import React from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"
import media from "styled-media-query"
import BackgroundImage from "gatsby-background-image"

import Layout from "../layout/Layout"
import Warning from "../components/Warning/Warning"

const QuemSomos = () => (
  <Layout title="Quem Somos">
    <StyledBackgroundSection />
    <WelcomeSection>
      <p>
        Desde 2003, a JN Net promove o acesso a internet de alta velocidade em
        João Neiva, Ibiraçu e Aracruz. Disponibilizamos sempre aos clientes o
        que há de mais moderno em conectividade através do serviço 100% fibra
        óptica.
        <br />
        <br />
        Estamos em constante evolução, então fique por dentro de todas as nossas
        novidades aqui no site, como planos, ofertas e área de cobertura.
      </p>
    </WelcomeSection>
    <Warning />
  </Layout>
)

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        quemSomos: file(relativePath: { eq: "quem_somos/quem_somos.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const quemSomos = data.quemSomos.childImageSharp.fluid

      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={quemSomos}
          backgroundColor="#040e18"
        >
          <Container>
            <TitleWrapper>
              <span>A internet de</span>
              <br />
              fibra ótica de João Neiva e Ibiraçu.
            </TitleWrapper>
          </Container>
        </BackgroundImage>
      )
    }}
  />
)

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: top center;
  background-repeat: no-repeat;
  height: 30vh;
  display: flex;
  justify-content: flex-start;

  ${media.greaterThan("medium")`
    height: 800px;
  `}
`

const Container = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.maxWidth}px;
  margin: 0 auto;
  margin-top: none;
  display: flex;

  ${media.greaterThan("medium")`
      margin-top: 20vh;
  `}
`

const TitleWrapper = styled.h1`
  font-size: 30px;
  max-width: 453px;
  padding: 20px;
  color: ${({ theme }) => theme.colors.white};
  line-height: 1.2;
  font-weight: bold;
  text-transform: uppercase;

  & span {
    font-weight: 300;
  }

  ${media.greaterThan("medium")`
    font-size: 49px;
    line-height: 1.2;
  `}
`

const WelcomeSection = styled.section`
  background-color: ${({ theme }) => theme.colors.secondary};
  padding: 30px;

  & p {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.white};
    width: 100%;
    max-width: ${({ theme }) => theme.sizes.maxWidth}px;
    margin: 0 auto;
    line-height: 1.2;
    font-weight: 300;
  }
`

export default QuemSomos
