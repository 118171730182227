import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

import DocumentIcon from "../../images/contact/document-icon.svg"
import GearIcon from "../../images/contact/gear-icon.svg"
import AttendanceIcon from "../../images/contact/attendance-icon.svg"

const Layout = () => (
  <>
    <Container>
      <Bar />
    </Container>
    <Content>
      <TextWrapper>
        <TitleText>ATENÇÃO CLIENTE JNNET:</TitleText>
        <Text>
          Você pode resolver vários assuntos pela "Minha JNNET", a área do
          cliente.
        </Text>
      </TextWrapper>

      <IconsWrapper>
        <IconWrapper href="https://minhajnnet.jnnet.com.br/#/auth/signin">
          <DocumentIcon />
          <Label>Segunda via</Label>
        </IconWrapper>

        <IconWrapper href="https://minhajnnet.jnnet.com.br/#/auth/signin">
          <GearIcon />

          <Label>Visita técnica</Label>
        </IconWrapper>

        <IconWrapper href="https://minhajnnet.jnnet.com.br/#/auth/signin">
          <AttendanceIcon />

          <Label>Atendimento ao cliente</Label>
        </IconWrapper>
        <IconWrapper href="https://minhajnnet.jnnet.com.br/#/auth/signin">
          <AttendanceIcon />

          <Label>Informe de pagamento</Label>
        </IconWrapper>

      </IconsWrapper>
    </Content>
  </>
)

const Container = styled.section`
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  display: flex;
  justify-content: flex-end;
`

const Bar = styled.div`
  width: 80vw;
  height: 49.52px;
  border-radius: 0 0 0 55px;
  background-color: ${({ theme }) => theme.colors.secondary};
`

const TextWrapper = styled.div`
  margin-right: 0;
  max-width: auto;
  margin-bottom: 20px;
  ${media.greaterThan("medium")`
    max-width: 400px;
    margin-right: 25px;
    margin-bottom: 0;
  `}
`

const Content = styled.section`
  max-width: ${({ theme }) => theme.sizes.maxWidth}px;
  min-height: 500px;
  background-color: ${({ theme }) => theme.colors.white};
  margin: 30px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px;

  ${media.greaterThan("medium")`
    flex-direction: row;
    flex-wrap: nowrap;
  `}
`

const TitleText = styled.p`
  font-size: 2.18rem;
  font-weight: bold;
  color: ${({ theme: appTheme }) => appTheme.colors.primary};
  margin-bottom: 25px;
`

const Text = styled.p`
  font-size: 1.34rem;
`

const IconWrapper = styled.a`
  background-color: ${({ theme: appTheme }) => appTheme.colors.primary};
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px;
  text-decoration: none;

  &:not(:last-child) {
    margin-right: 10px;
  }
  border-radius: 15px;

  ${media.greaterThan("medium")`
    flex-wrap: nowrap;
    width: 233px;
    height: 260px;
  `}
`

const IconsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  ${media.greaterThan("medium")`
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  `}
`

const Label = styled.p`
  text-align: center;
  color: ${({ theme: appTheme }) => appTheme.colors.white};
  font-weight: 300;
  margin-top: 20px;
`

export default Layout
